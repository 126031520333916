import { useState } from 'react';

const validators = {
    isNull: (value: any) => (value === null ? 'Field is required!' : null),
    isEmpty: (value: any) => (!value.trim().length ? 'Field cannot be empty!' : null),
    isEmailCorrect: (value: any) => (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? null : 'Invalid email format'),
    minLength: (minLength: number) => (value: any) =>
        value.length < minLength ? `Minimum length is ${minLength} characters` : null
};

type FormValues<T = any> = {
    [key: string]: T;
};

type ErrorMessages = {
    [key: string]: string | null;
};

type ValidationFunction<T = any> = (value: T) => string | null;

type ValidationConfig<T = any> = {
    [key in keyof T]: ValidationFunction<T[key]>[];
};

const useValidation = <T extends FormValues>(initialState: T, validationConfig: ValidationConfig<T>) => {
    const [values, setValues] = useState<T>(initialState);
    const [errors, setErrors] = useState<ErrorMessages>({});

    // Handle field changes with validation
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checkBoolean = false) => {
        const { name, value, checked } = event.target;

        setValues({
            ...values,
            [name]: checkBoolean ? checked : value
        });

        if (validationConfig[name]) {
            validateField(name, value, validationConfig[name]);
        }
    };

    const validateField = <K extends keyof T>(
        fieldName: string,
        fieldValue: any,
        fieldValidators: ValidationFunction<T[K]>[]
    ): boolean => {
        for (const validator of fieldValidators) {
            const error = validator(fieldValue);
            if (error) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    [fieldName]: error
                }));
                return false;
            }
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            [fieldName]: null
        }));
        return true;
    };

    const validateForm = (): boolean => {
        let isValid = true;
        for (const fieldName in validationConfig) {
            const fieldValidators = validationConfig[fieldName];
            const fieldValue = values[fieldName];
            const fieldIsValid = validateField(fieldName, fieldValue, fieldValidators);
            if (!fieldIsValid) isValid = false;
        }
        return isValid;
    };

    return {
        values,
        errors,
        handleChange,
        validateField,
        validateForm
    };
};

export { useValidation, validators };

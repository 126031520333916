import React, { useEffect } from 'react';
import styles from '../Secure.module.scss';
import { useHeader } from '../../../context/HeaderContext';
import { useAuth } from '../../../context/AuthContext';
import { SwitchButton } from './controls/SwitchButton';
import { ProfileSettingsTextField } from './controls/ProfileSettingsTextField';
import { useValidation, validators } from '../../../hooks/useValidation';
import { Methods, useFetch } from '../../../hooks/useFetch';
import { useGlobalState } from '../../../context/GlobalStateContext';

export const ProfileSettings: React.FC = () => {
    const { setConfig } = useHeader();
    const { user: initialUser } = useAuth();
    const { setInfo } = useGlobalState();

    useEffect(() => {
        setConfig({
            title: 'Settings'
        });
    }, []);

    const validationConfig = {
        username: [validators.isNull, validators.isEmpty],
        name: [validators.isNull, validators.isEmpty],
        email: [validators.isNull, validators.isEmpty, validators.isEmailCorrect],
        public: []
    };

    const { makeRequest: logOut } = useFetch(`/api/logout`, { method: Methods.GET });

    const handleLogOut = async () => {
        await logOut();
        window.location.href = '/';
    };

    const {
        values: user,
        errors,
        handleChange,
        validateForm
    } = useValidation(
        {
            name: initialUser?.name,
            username: initialUser?.username,
            email: initialUser?.email,
            public: initialUser?.public
        },
        validationConfig
    );

    const { makeRequest: saveChanges } = useFetch(`/api/profile/update`, { method: Methods.POST, body: user }, true);

    const handleSaveChanges = async () => {
        if (validateForm()) {
            await saveChanges();
            setInfo('Your profile has been updated!');
        }
    };

    return (
        <div className={styles.profileSettingsWrapper}>
            <div className={styles.profileSettingsContent}>
                <ProfileSettingsTextField
                    label="Username"
                    value={user?.username}
                    name="username"
                    error={errors.username}
                    onChange={handleChange}
                />
                <ProfileSettingsTextField
                    label="Name"
                    name="name"
                    value={user?.name}
                    error={errors.name}
                    onChange={handleChange}
                />
                <ProfileSettingsTextField
                    label="Email"
                    name="email"
                    value={user?.email}
                    error={errors.email}
                    onChange={handleChange}
                />
                <div className={styles.profileSettingsContentRow}>
                    <div className={styles.profileSettingsContentRowPublicProfileLabel}>{'Public Profile'}</div>
                    <div className={styles.profileSettingsContentRowPublicProfileButton}>
                        <SwitchButton value={user?.public} onChange={e => handleChange(e, true)} name="public" />
                    </div>
                </div>
                <div className={styles.profileSettingsButtons}>
                    <div style={{ cursor: 'pointer' }} onClick={handleSaveChanges}>
                        <div className={styles.profileSettingsSaveChanges}>Save Changes</div>
                    </div>
                    <div style={{ cursor: 'pointer' }} onClick={handleLogOut}>
                        <div className={styles.profileSettingsLogOut}>Log out</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

import React, { useEffect, useMemo, useState } from 'react';
import { useFetch, Methods } from '../../../hooks/useFetch';
import { useAuth } from '../../../context/AuthContext';
import { useParams } from 'react-router-dom';
import { Fixture } from '../../../types/DbModels';
import { FixturesListing } from '../browse/FixturesListing';
import { FixtureDetails } from '../fixture/FixtureDetails';
import styles from '../Secure.module.scss';
import { ComponentLoader } from '../../../components/ComponentLoader';
import { useHeader } from '../../../context/HeaderContext';

export const LiveFixtures: React.FC = () => {
    const { user } = useAuth();
    const params = useParams();
    const { setConfig } = useHeader();
    const [liveFixtures, setLiveFixtures] = useState<Array<Fixture> | null>(null);
    const [liveFixture, setLiveFixture] = useState<Fixture | null>(null);

    const parsedFixtureId = params.fixture_id ? parseInt(params.fixture_id) : null;

    const { makeRequest: fetchLiveFixtures } = useFetch(`/api/models/view_fixtures`, {
        method: Methods.GET,
        params: { custom_filter: `(user_id IS NULL OR user_id = ${user?.id})`, live: 1 }
    });

    const { makeRequest: fetchLiveFixture } = useFetch(`/api/models/view_fixtures`, {
        method: Methods.GET,
        params: {
            custom_filter: `(user_id IS NULL OR user_id = ${user?.id})`,
            id: parsedFixtureId
        }
    });

    useEffect(() => {
        if (!parsedFixtureId) {
            const handleFetchLiveFixtures = async () => {
                const { data: response } = await fetchLiveFixtures();
                setLiveFixtures(response.data);
            };

            handleFetchLiveFixtures();
        }
    }, [parsedFixtureId]);

    useEffect(() => {
        if (parsedFixtureId) {
            const handleFetchLiveFixtures = async () => {
                const { data: response } = await fetchLiveFixture();
                if (response.data.length) {
                    setLiveFixture(response.data[0]);
                }
            };

            handleFetchLiveFixtures();
        }
    }, [parsedFixtureId]);

    const { title, element, hideSearchBar, condition } = useMemo(() => {
        if (parsedFixtureId) {
            return {
                title: '',
                hideSearchBar: true,
                condition: liveFixture !== null,
                element: <FixtureDetails fixture={liveFixture ? liveFixture : ({} as Fixture)} />
            };
        }

        return {
            title: 'Live',
            hideSearchBar: true,
            condition: liveFixtures !== null,
            element: <FixturesListing fixtures={liveFixtures ? liveFixtures : ([] as Array<Fixture>)} />
        };
    }, [parsedFixtureId, liveFixtures, liveFixture]);

    useEffect(() => {
        setConfig({ title: title });
    }, [title]);

    return (
        <div className={`${styles.browseWrapper} ${hideSearchBar ? styles.hideSearchBar : ''}`}>
            {condition ? element : <ComponentLoader isLoading />}
        </div>
    );
};

import React from 'react';
import styles from '../Secure.module.scss';
import { IconButton } from '@mui/material';
import { useHeader } from '../../../context/HeaderContext';

export const Header: React.FC = () => {
    const { config } = useHeader();

    return (
        <div className={styles.headerWrapper}>
            {config.icon1 && config.icon1Click ? (
                <IconButton onClick={config.icon1Click} sx={{ color: '#fff' }}>
                    {config.icon1}
                </IconButton>
            ) : (
                <div></div>
            )}
            <div className={styles.headerTitle}>{config.title}</div>
            {config.icon2 && config.icon2Click ? (
                <IconButton sx={{ color: '#fff' }} onClick={config.icon2Click}>
                    {config.icon2}
                </IconButton>
            ) : (
                <div></div>
            )}
        </div>
    );
};

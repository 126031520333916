import React, { useEffect, useState } from "react";
import { useFetch, Methods } from "../hooks/useFetch";
import PublicNavigationWrapper from "../pages/public/PublicNavigationWrapper";
import SecureNavigationWrapper from "../pages/secure/SecureNavigationWrapper";
import { useAuth } from "../context/AuthContext";

const AuthenticatedApp: React.FC = () => {
  const [authChecked, setIsAuthChecked] = useState<boolean>(false);

  const { isAuthenticated, setIsAuthenticated, setAuthToken, setUser } =
    useAuth();

  const { makeRequest: checkAuth } = useFetch(
    `/api/check-auth`,
    {
      method: Methods.GET,
    },
    false
  );

  useEffect(() => {
    const handleCheckAuth = async () => {
      const { data: response } = await checkAuth();
      if (
        response &&
        response.hasOwnProperty("data") &&
        response.data &&
        response.data.hasOwnProperty("token") &&
        response.data.hasOwnProperty("user")
      ) {
        setAuthToken(response.data.token);
        setUser(response.data.user);
        setIsAuthenticated(true);
      }

      setIsAuthChecked(true);
    };

    handleCheckAuth();
  }, []);

  if (!authChecked) {
    return;
  }

  return (
    <>
      {isAuthenticated ? (
        <SecureNavigationWrapper />
      ) : (
        <PublicNavigationWrapper />
      )}
    </>
  );
};

export { AuthenticatedApp };

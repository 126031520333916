import { useCallback, useMemo } from 'react';
import { useGlobalState } from '../context/GlobalStateContext';
import { useLoading } from '../context/LoadingContext';
import { useAuth } from '../context/AuthContext';

interface FetchOptions extends RequestInit {
    body?: any;
    method?: string;
    headers?: Headers;
    params?: any;
}

enum Methods {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE'
}

export interface UseFetchResponse {
    data: any;
    error: any;
}

interface UseFetchResult {
    error: string | null;
    makeRequest: (options?: FetchOptions) => Promise<UseFetchResponse>;
    loading: boolean;
}

function useFetch<T>(endpoint: string, defaultOptions: FetchOptions, showError: boolean = true): UseFetchResult {
    const { loading, setLoading } = useLoading();
    const { setAuthToken, authToken } = useAuth();
    const { setError, error } = useGlobalState();

    const url = useMemo(() => `${process.env.REACT_APP_API_URL}${endpoint}`, [endpoint]);

    const makeRequest = useCallback(
        async (dynamicOptions: FetchOptions = {}) => {
            setLoading(true);
            let errorMsg = null;
            let resultData = null;

            const options = {
                ...defaultOptions,
                ...dynamicOptions
            };

            if (!options.headers) {
                options.headers = new Headers({
                    'Content-Type': 'application/json'
                });
            }

            if (authToken) {
                options.headers.set('Authorization', `Bearer ${authToken}`);
            }

            options.credentials = 'include';

            options.body = options.body ? JSON.stringify(options.body) : undefined;

            let finalUrl = url;

            if (options.method === Methods.GET && options.params) {
                const searchParams = new URLSearchParams(options.params).toString();
                finalUrl = `${url}?${searchParams}`;
            }

            try {
                const response: Response = await fetch(finalUrl, options);

                if (!response.ok) {
                    let errorMessage = '';
                    const errorObject = await response.json();
                    if (errorObject && errorObject.hasOwnProperty('error')) {
                        errorMessage = errorObject.error;
                    }
                    if (showError) {
                        setError(errorMessage);
                    }
                    return { data: {}, error: errorMessage };
                }

                const newAccessToken = response.headers.get('Authorization');
                if (newAccessToken) {
                    setAuthToken(newAccessToken.split(' ')[1]);
                }

                const result: T = await response.json();
                resultData = result;
            } catch (error: any) {
                errorMsg = error;

                if (error instanceof Error) {
                    console.error(error);
                    try {
                        errorMsg = JSON.parse(error.message);
                    } catch {
                        errorMsg = { error: error.message };
                    }
                }
                if (showError) {
                    setError(errorMsg);
                }
            } finally {
                setLoading(false);
            }

            return { data: resultData, error: errorMsg } as UseFetchResponse;
        },
        [url, defaultOptions, setLoading, setError, setAuthToken, showError]
    );

    return { error, makeRequest, loading };
}

export { useFetch, Methods };

import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { LoaderProps } from '../types/Generic';

export const ComponentLoader: React.FC<LoaderProps> = ({
    isLoading,
    message,
    size = 40,
    color = 'primary',
    ...props
}) => {
    if (!isLoading) return null;

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
            gap={2}
        >
            <CircularProgress size={size} color={color} {...props} />
            {message && <Typography variant="body2">{message}</Typography>}
        </Box>
    );
};

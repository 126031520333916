import React, { FC } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface SearchBarProps {
    searchTerm: string;
    setSearchTerm: (value: string) => void;
    maxWidth?: string;
    searchLabel?: string;
    className?: string;
}

export const SearchBar: FC<SearchBarProps> = ({
    searchTerm,
    setSearchTerm,
    maxWidth = '360px',
    searchLabel = 'Search...',
    className
}) => (
    <TextField
        fullWidth
        variant="outlined"
        className={className ? className : ''}
        placeholder={searchLabel}
        value={searchTerm}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <SearchIcon style={{ color: '#aaa' }} />
                </InputAdornment>
            )
        }}
        sx={{
            borderRadius: '8px',
            backgroundColor: 'transparent',
            maxWidth: maxWidth,
            margin: '10px',
            '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
                backgroundColor: '#2b2b2b',
                color: '#FFF',
                '& fieldset': {
                    borderColor: '#444'
                },
                '&:hover fieldset': {
                    borderColor: '#444'
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#444',
                    borderWidth: '1px'
                }
            },
            '& .MuiInputBase-input': {
                padding: '10px 14px'
            }
        }}
    />
);

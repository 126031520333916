import React from "react";
import styles from "../Secure.module.scss";
import { FixtureListingCard } from "./FixtureListingCard";
import { Fixture } from "../../../types/DbModels";

interface FixturesListingProps {
  fixtures: Array<Fixture>;
}

export const FixturesListing: React.FC<FixturesListingProps> = ({
  fixtures = [],
}) => {
  const renderFixtures = () => {
    if (!fixtures.length) {
      return <div className={styles.listingNotFound}>Fixtures not found</div>;
    }

    return fixtures.map((f: Fixture) => {
      return <FixtureListingCard key={f.id} fixture={f} />;
    });
  };

  return (
    <div className={styles.countriesListingWrapper}>
      <div className={styles.browseCountriesListing} style={{ padding: 0 }}>
        {renderFixtures()}
      </div>
    </div>
  );
};

import React, { useEffect, useMemo, useState } from 'react';
import styles from '../Secure.module.scss';
import { useHeader } from '../../../context/HeaderContext';
import { SearchBar } from '../controls/SearchBar';
import { UserListing } from './components/UserListing';
import { Methods, useFetch } from '../../../hooks/useFetch';
import { User } from '../../../context/AuthContext';
import { useParams } from 'react-router-dom';
import { ComponentLoader } from '../../../components/ComponentLoader';
import { Profile } from './Profile';

export const SearchUsers: React.FC = () => {
    const { setConfig } = useHeader();
    const params = useParams();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [users, setUsers] = useState<Array<User> | null>(null);

    const userId = params.id ? parseInt(params.id) : null;

    const { makeRequest: fetchUsers } = useFetch(`/api/users`, { method: Methods.GET });

    useEffect(() => {
        const handleFetchUsers = async () => {
            const { data: response } = await fetchUsers();

            setUsers(response.data);
        };

        handleFetchUsers();
    }, []);

    const searchedUsers = useMemo(() => {
        if (!users) {
            return null;
        }

        if (!searchTerm.trim().length) return users;

        return users.filter((user: User) => user.username?.toLowerCase().includes(searchTerm.toLowerCase()));
    }, [searchTerm, users]);

    const { title, element, condition } = useMemo(() => {
        if (userId) {
            return {
                title: 'User',
                condition: true,
                hideSearchBar: false,
                element: <Profile />
            };
        }

        return {
            title: 'Search for users',
            hideSearchBar: false,
            condition: users !== null,
            element: (
                <div className={styles.searchUsersContent}>
                    <SearchBar
                        className={styles.searchUsersSearch}
                        searchTerm={searchTerm}
                        searchLabel={'Search by username'}
                        setSearchTerm={setSearchTerm}
                    />
                    <UserListing users={searchedUsers ? searchedUsers : ([] as Array<User>)} />
                </div>
            )
        };
    }, [searchedUsers, userId]);

    useEffect(() => {
        setConfig({ title: title });
    }, [title]);

    return <div className={styles.searchUsersWrapper}>{condition ? element : <ComponentLoader isLoading />}</div>;
};

import React from 'react';
import styles from '../Secure.module.scss';
import {
    HomeOutlined,
    SportsSoccerOutlined,
    StarBorderOutlined,
    AccountCircleOutlined,
    Search
} from '@mui/icons-material';
import { FooterButton } from './FooterButton';

export const Footer: React.FC = () => {
    const footerButtons = [
        {
            text: 'Home',
            icon: <HomeOutlined />,
            href: '/'
        },
        {
            text: 'Browse',
            icon: <Search />,
            href: '/browse'
        },
        {
            text: 'Live',
            icon: <SportsSoccerOutlined />,
            href: '/live'
        },
        {
            text: 'Favorites',
            icon: <StarBorderOutlined />,
            href: '/favorites'
        },
        {
            text: 'Profile',
            icon: <AccountCircleOutlined />,
            href: '/profile'
        }
    ];

    return (
        <div className={styles.footerWrapper}>
            {footerButtons.map((fb: any, index: number) => (
                <FooterButton text={fb.text} href={fb.href} key={index}>
                    {fb.icon}
                </FooterButton>
            ))}
        </div>
    );
};

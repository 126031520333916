import React, { useEffect } from 'react';
import styles from '../Secure.module.scss';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { useHeader } from '../../../context/HeaderContext';

export const MyLeagues: React.FC = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const { setConfig } = useHeader();

    useEffect(() => {
        setConfig({ title: '', icon1: null });
    }, []);

    return (
        <div className={styles.myLeaguesWrapper}>
            <img
                src={`${process.env.PUBLIC_URL}/assets/images/logo@2x.png`}
                alt="BetBook Logo"
                className={styles.myLeaguesLogo}
            />
            <div className={styles.myLeaguesWelcome}>Welcome {user?.username}!</div>
            <div className={styles.myLeaguesSelectLeagues} onClick={() => navigate('/browse')}>
                Select your favorite leagues and start your journey!
            </div>
        </div>
    );
};

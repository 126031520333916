import React from "react";
import styles from "../Secure.module.scss";
import { useNavigate } from "react-router-dom";

interface LeagueListingCardProps {
  flag: string | null;
  name: string | null;
  fixtures_count: number | null;
  leagues_count: number | null;
  id: number;
}

export const CountryListingCard: React.FC<LeagueListingCardProps> = ({
  flag,
  name,
  fixtures_count,
  leagues_count,
  id,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={styles.browseCountryListingWidget}
      onClick={() => navigate(`/browse/${id}`)}
    >
      <img
        src={flag ? `${process.env.REACT_APP_API_URL}/api/static${flag}` : ""}
        loading="lazy"
        alt={`Flag for ${name}`}
        className={styles.browseCountryListingWidgetFlag}
      />
      <div className={styles.browseCountryListingWidgetInformation}>
        <div className={styles.browseCountryListingWidgetLeagues}>
          {leagues_count} leagues
        </div>
        <div className={styles.browseCountryListingWidgetCountry}>{name}</div>
        <div className={styles.browseCountryListingWidgetFixtures}>
          {fixtures_count} matches
        </div>
      </div>
    </div>
  );
};

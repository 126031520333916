import React, { useEffect, useMemo, useState } from 'react';
import { useFetch, Methods } from '../../../hooks/useFetch';
import { CountriesListing } from './CountriesListing';
import styles from '../Secure.module.scss';
import { useParams } from 'react-router-dom';
import { LeaguesListing } from './LeagueListing';
import { SearchBar } from '../controls/SearchBar';
import { CountryLeague, Fixture, League } from '../../../types/DbModels';
import { useAuth } from '../../../context/AuthContext';
import { FixturesListing } from './FixturesListing';
import { FixtureDetails } from '../fixture/FixtureDetails';
import { ComponentLoader } from '../../../components/ComponentLoader';
import { useHeader } from '../../../context/HeaderContext';

export const Browse: React.FC = () => {
    const { user } = useAuth();
    const params = useParams();
    const { config, setConfig } = useHeader();

    const parsedCountryId = params.id ? parseInt(params.id) : null;
    const parsedLeagueId = params.league_id ? parseInt(params.league_id) : null;
    const parsedFixtureId = params.fixture_id ? parseInt(params.fixture_id) : null;

    const [searchTerm, setSearchTerm] = useState('');
    const [countryLeagues, setCountryLeagues] = useState<Array<CountryLeague> | null>(null);
    const [leagues, setLeagues] = useState<Array<League> | null>(null);
    const [fixtures, setFixtures] = useState<Array<Fixture> | null>(null);
    const [fixture, setFixture] = useState<Fixture | null>(null);

    const { makeRequest: fetchCountryLeagues } = useFetch(
        `/api/models/view_country_leagues`,
        {
            method: Methods.GET
        },
        true
    );

    const { makeRequest: fetchLeagues } = useFetch(
        `/api/leagues`,
        {
            method: Methods.GET
        },
        true
    );

    const { makeRequest: fetchFixtures } = useFetch(`/api/models/view_fixtures`, {
        method: Methods.GET,
        params: { custom_filter: `(user_id IS NULL OR user_id = ${user?.id})` }
    });

    const { makeRequest: fetchFixture } = useFetch(`/api/models/view_fixtures`, {
        method: Methods.GET,
        params: {
            custom_filter: `(user_id IS NULL OR user_id = ${user?.id})`,
            id: parsedFixtureId
        }
    });

    const resetState = () => {
        setSearchTerm('');
        setLeagues(null);
    };

    useEffect(() => {
        if (!parsedCountryId && !parsedFixtureId && !parsedLeagueId) {
            const handleFetchCountryLeagues = async () => {
                const { data: response } = await fetchCountryLeagues();
                setCountryLeagues(response.data);
            };

            handleFetchCountryLeagues();
        }
    }, [parsedCountryId, parsedFixtureId, parsedLeagueId]);

    useEffect(() => {
        if (parsedCountryId && !parsedLeagueId && !parsedFixtureId) {
            const handleFetchLeagues = async () => {
                const { data: response } = await fetchLeagues({
                    params: { country_id: parsedCountryId }
                });

                const leagues = response.data;

                setSearchTerm('');
                setLeagues(leagues);
            };

            handleFetchLeagues();
            return;
        }

        resetState();
    }, [parsedCountryId, parsedLeagueId, parsedFixtureId]);

    useEffect(() => {
        if (parsedLeagueId && !parsedFixtureId) {
            const handleFetchFixtures = async () => {
                const { data: response } = await fetchFixtures({
                    params: { league_id: parsedLeagueId }
                });

                setFixtures(response.data);
                setSearchTerm('');
            };

            handleFetchFixtures();
            return;
        }

        resetState();
    }, [parsedLeagueId, parsedFixtureId]);

    useEffect(() => {
        if (parsedFixtureId) {
            const handleFetchFixture = async () => {
                const { data: response } = await fetchFixture();

                setFixture(response.data[0]);
                setSearchTerm('');
            };

            handleFetchFixture();
            return;
        }

        resetState();
    }, [parsedFixtureId]);

    const searchedLeagues = useMemo(() => {
        if (!leagues) {
            return null;
        }

        if (!searchTerm.trim().length) return leagues;

        return leagues.filter((league: League) => league.name?.toLowerCase().includes(searchTerm.toLowerCase()));
    }, [searchTerm, leagues]);

    const searchedCountryLeagues = useMemo(() => {
        if (!countryLeagues) {
            return null;
        }

        if (!searchTerm.trim().length) return countryLeagues;

        return countryLeagues.filter((countryLeague: CountryLeague) =>
            countryLeague.name?.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [searchTerm, countryLeagues]);

    const { title, element, hideSearchBar, condition } = useMemo(() => {
        if (parsedCountryId && parsedLeagueId && parsedFixtureId) {
            return {
                title: 'Fixture',
                hideSearchBar: true,
                condition: fixture !== null,
                element: <FixtureDetails fixture={fixture ? fixture : ({} as Fixture)} />
            };
        }

        if (parsedCountryId && parsedLeagueId) {
            return {
                title: 'Fixtures',
                hideSearchBar: true,
                condition: fixtures !== null,
                element: <FixturesListing fixtures={fixtures ? fixtures : ([] as Array<Fixture>)} />
            };
        }

        if (parsedCountryId && !parsedLeagueId) {
            return {
                title: 'Leagues',
                hideSearchBar: false,
                condition: searchedLeagues !== null,
                element: <LeaguesListing leagues={searchedLeagues ? searchedLeagues : ([] as Array<League>)} />
            };
        }

        return {
            title: 'Countries',
            hideSearchBar: false,
            condition: searchedCountryLeagues !== null,
            element: (
                <CountriesListing
                    countryLeagues={searchedCountryLeagues ? searchedCountryLeagues : ([] as Array<CountryLeague>)}
                />
            )
        };
    }, [parsedFixtureId, parsedCountryId, parsedLeagueId, fixtures, fixture, searchedLeagues, searchedCountryLeagues]);

    useEffect(() => {
        setConfig({
            title: title
        });
    }, [title]);

    return (
        <div className={`${styles.browseWrapper} ${hideSearchBar ? styles.hideSearchBar : ''}`}>
            {condition ? (
                <>
                    {hideSearchBar ? null : <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />}
                    {element}
                </>
            ) : (
                <ComponentLoader isLoading />
            )}
        </div>
    );
};

import React from 'react';
import styles from '../Public.module.scss';
import { CustomTextInput as TextField, InputType } from '../components/CustomTextInput';
import { useNavigate } from 'react-router-dom';
import { useFetch, Methods } from '../../../hooks/useFetch';
import { useValidation, validators } from '../../../hooks/useValidation';
import { useGlobalState } from '../../../context/GlobalStateContext';
import { useAuth } from '../../../context/AuthContext';

const Login: React.FC = () => {
    const navigate = useNavigate();

    interface User {
        email: string;
        password: string;
    }

    const validationConfig = {
        email: [validators.isNull, validators.isEmpty, validators.isEmailCorrect],
        password: [validators.isNull, validators.isEmpty]
    };

    const { setAuthToken, setIsAuthenticated, setUser } = useAuth();
    const { error } = useGlobalState();

    const {
        values: user,
        errors,
        handleChange,
        validateForm
    } = useValidation(
        {
            email: '',
            password: ''
        },
        validationConfig
    );

    const { makeRequest: loginUser, loading } = useFetch<User>(`/api/login`, {
        method: Methods.POST,
        body: user
    });

    const handleLogin = async () => {
        if (validateForm()) {
            const { data: response } = await loginUser();

            if (
                response &&
                response.hasOwnProperty('data') &&
                response.data &&
                response.data.hasOwnProperty('token') &&
                response.data.hasOwnProperty('user')
            ) {
                setAuthToken(response.data.token);
                setIsAuthenticated(true);
                setUser(response.data.user);
                navigate('/');
                return;
            }
        }
    };

    return (
        <div className={styles.wrapperContent}>
            <div className={styles.container}>
                <div className={styles.backgroundImage}>
                    <div className={styles.overlayLogin}>
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/images/logo@2x.png`}
                            alt="BetBook Logo"
                            className={styles.logo}
                            onClick={() => navigate('/')}
                        />
                        <div className={styles.overlayContent}>
                            <TextField
                                name="email"
                                placeholder="Email"
                                label="Email"
                                type={InputType.Text}
                                value={user.email}
                                error={Boolean(errors.email) || Boolean(error)}
                                errorMessage={errors.email || error}
                                onChange={handleChange}
                                onEnter={handleLogin}
                            />
                            <TextField
                                name="password"
                                label="Password"
                                placeholder="Password"
                                type={InputType.Password}
                                value={user.password}
                                error={Boolean(errors.password) || Boolean(error)}
                                errorMessage={errors.password || error}
                                onChange={handleChange}
                                onEnter={handleLogin}
                            />
                        </div>
                        <button className={styles.forgotPassword} onClick={() => navigate('/forgot-password')}>
                            I forgot my password
                        </button>
                        <div className={styles.overlayButtons}>
                            <button
                                className={styles.createAccount}
                                onClick={handleLogin}
                                disabled={loading || Object.keys(errors).some(error => errors[error] !== null)}
                            >
                                Sign in
                            </button>
                            <button className={styles.alreadyAccount} onClick={() => navigate('/register')}>
                                I don't have an account
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;

import React, { createContext, useContext, useState } from 'react';
import { Methods, useFetch } from '../hooks/useFetch';

interface GlobalState {
    info: string | null;
    setInfo: (info: string | null) => void;
    error: string | null;
    setError: (error: string | null) => void;
    title: string | null;
    setTitle: (title: string | null) => void;
}

const GlobalStateContext = createContext<GlobalState | undefined>(undefined);

export const GlobalStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [info, setInfo] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [title, setTitle] = useState<string | null>(null);

    return (
        <GlobalStateContext.Provider
            value={{
                info,
                setInfo,
                error,
                setError,
                title,
                setTitle
            }}
        >
            {children}
        </GlobalStateContext.Provider>
    );
};

export const useGlobalState = (): GlobalState => {
    const context = useContext(GlobalStateContext);
    if (!context) {
        throw new Error('useGlobalState must be used within a GlobalStateProvider');
    }
    return context;
};

import React from "react";
import styles from "./Public.module.scss";
import { useNavigate } from "react-router-dom";

const Public: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapperContent}>
      <div className={styles.container}>
        <div className={styles.backgroundImage}>
          <div className={styles.overlay}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/logo@2x.png`}
              alt="BetBook Logo"
              className={styles.logo}
              onClick={() => navigate("/")}
            />
            <div></div>
            <div className={styles.overlayButtons}>
              <button
                className={styles.createAccount}
                onClick={() => navigate("/register")}
              >
                Create Account
              </button>
              <button
                className={styles.alreadyAccount}
                onClick={() => navigate("/login")}
              >
                I already have an account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Public;

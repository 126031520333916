import React from "react";
import styles from "../Public.module.scss";
import {
  CustomTextInput as TextField,
  InputType,
} from "../components/CustomTextInput";
import { useNavigate } from "react-router-dom";
import { useFetch, Methods } from "../../../hooks/useFetch";
import { useValidation, validators } from "../../../hooks/useValidation";

const Register: React.FC = () => {
  const navigate = useNavigate();

  interface User {
    username: string;
    email: string;
    password: string;
  }

  const validationConfig = {
    username: [validators.isNull, validators.isEmpty],
    email: [validators.isNull, validators.isEmpty, validators.isEmailCorrect],
    password: [validators.isNull, validators.isEmpty],
  };

  const {
    values: user,
    errors,
    handleChange,
    validateForm,
  } = useValidation(
    {
      username: "",
      email: "",
      password: "",
    },
    validationConfig
  );

  const { makeRequest: registerUser, loading } = useFetch<User>(
    `/api/register`,
    {
      method: Methods.POST,
      body: user,
    }
  );

  const handleRegister = async () => {
    if (validateForm()) {
      const { error } = await registerUser();

      if (!error) {
        navigate("/login");
      }
    }
  };

  return (
    <div className={styles.wrapperContent}>
      <div className={styles.container}>
        <div className={styles.backgroundImage}>
          <div className={styles.overlay}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/logo@2x.png`}
              alt="BetBook Logo"
              className={styles.logo}
              onClick={() => navigate("/")}
            />
            <div className={styles.overlayContent}>
              <TextField
                name="username"
                placeholder="Username"
                label="Username"
                type={InputType.Text}
                value={user.username}
                error={Boolean(errors.username)}
                errorMessage={errors.username}
                onChange={handleChange}
              />
              <TextField
                name="email"
                placeholder="Email"
                label="Email"
                type={InputType.Text}
                value={user.email}
                error={Boolean(errors.email)}
                errorMessage={errors.email}
                onChange={handleChange}
              />
              <TextField
                name="password"
                label="Password"
                placeholder="Password"
                type={InputType.Password}
                value={user.password}
                error={Boolean(errors.password)}
                errorMessage={errors.password}
                onChange={handleChange}
              />
            </div>
            <div className={styles.overlayButtons}>
              <button
                className={styles.createAccount}
                onClick={handleRegister}
                disabled={
                  loading ||
                  Object.keys(errors).some((error) => errors[error] !== null)
                }
              >
                Continue
              </button>
              <button
                className={styles.alreadyAccount}
                onClick={() => navigate("/login")}
              >
                I already have an account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;

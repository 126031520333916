import React, { useState } from 'react';
import { TextField, InputLabel, FormControl, InputAdornment, IconButton, Tooltip } from '@mui/material';
import styles from '../Public.module.scss';
import { Visibility, VisibilityOff, Warning } from '@mui/icons-material';

enum InputType {
    Text = 'text',
    Number = 'number',
    Password = 'password'
}

interface CustomTextInputProps {
    name: string;
    label: string;
    placeholder: string;
    type: InputType;
    disabled?: boolean;
    error?: boolean;
    errorMessage?: string | null;
    multiline?: boolean;
    rows?: number;
    value: any;
    onChange: (arg1: any) => void;
    onEnter?: () => void;
}

const CustomTextInput: React.FC<CustomTextInputProps> = ({
    label,
    placeholder,
    type,
    disabled,
    error,
    errorMessage,
    multiline,
    rows,
    value,
    name,
    onChange,
    onEnter
}) => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    let processedType = type;

    if (type === InputType.Password && passwordVisible) {
        processedType = InputType.Text;
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && onEnter) {
            onEnter();
        }
    };

    return (
        <FormControl fullWidth sx={{ maxWidth: '305px' }} variant="filled">
            <InputLabel
                sx={{
                    textAlign: 'center',
                    position: 'relative',
                    color: 'white',
                    margin: '0 auto',
                    width: 'fit-content',
                    top: '0px',
                    transform: 'translateY(-50%)',
                    fontSize: '16px',
                    '&.Mui-focused': {
                        fontSize: '16px'
                    }
                }}
            >
                {label}
            </InputLabel>
            <TextField
                name={name}
                className={styles.customTextInput}
                type={processedType}
                placeholder={placeholder}
                disabled={disabled}
                error={error}
                variant="filled"
                multiline={multiline}
                rows={rows}
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                slotProps={{
                    input: {
                        autoComplete: 'new-password',
                        endAdornment: (
                            <InputAdornment position="end" sx={{ height: '100%' }}>
                                {error ? (
                                    <Tooltip
                                        title={errorMessage || ''}
                                        arrow
                                        slotProps={{
                                            tooltip: {
                                                sx: {
                                                    color: 'red', // Set tooltip text color to red
                                                    fontSize: '12px',
                                                    fontWeight: 'bold',
                                                    bgcolor: 'white' // Optional: change background color
                                                }
                                            }
                                        }}
                                    >
                                        <IconButton
                                            sx={{
                                                padding: '0px !important',
                                                color: 'red',
                                                maxHeight: '21px'
                                            }}
                                            aria-label="error message"
                                        >
                                            <Warning />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    type === InputType.Password && (
                                        <IconButton
                                            sx={{
                                                padding: '0px !important',
                                                maxHeight: '21px'
                                            }}
                                            aria-label="toggle password visibility"
                                            onClick={() => setPasswordVisible(!passwordVisible)}
                                        >
                                            {passwordVisible ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    )
                                )}
                            </InputAdornment>
                        )
                    }
                }}
                sx={{
                    maxWidth: '305px',
                    background: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: '25px',
                    '& .MuiFilledInput-root': {
                        background: 'rgba(255, 255, 255, 0.8)',
                        borderRadius: '25px',
                        padding: '10px',
                        fontSize: '16px',
                        minHeight: '40px'
                    },
                    '& .MuiFilledInput-input': {
                        borderRadius: '25px',
                        padding: '0 20px',
                        fontSize: '16px',
                        height: '100%'
                    },
                    '& .MuiInputAdornment-root': {
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%'
                    },
                    '& input:-webkit-autofill': {
                        fontSize: '16px',
                        WebkitTextFillColor: '#000',
                        backgroundColor: 'transparent !important',
                        transition: 'background-color 5000s ease-in-out 0s'
                    },
                    '& .MuiFilledInput-root:before': {
                        display: 'none'
                    },
                    '& .MuiFilledInput-root:after': {
                        display: 'none'
                    }
                }}
            />
        </FormControl>
    );
};

export { CustomTextInput, InputType };

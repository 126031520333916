import React from 'react';
import { TextField } from '@mui/material';

interface ProfileSettingsTextFieldProps {
    label: string;
    value: string | null | undefined;
    error?: string | null | undefined;
    type?: string;
    name: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ProfileSettingsTextField: React.FC<ProfileSettingsTextFieldProps> = ({
    label,
    value,
    type = 'text',
    onChange,
    error,
    name
}) => {
    return (
        <TextField
            name={name}
            variant="standard"
            label={label}
            value={value ? value : ''}
            type={type}
            onChange={onChange}
            error={Boolean(error)}
            helperText={error || ' '}
            fullWidth
            InputProps={{
                style: {
                    color: '#fff'
                },
                sx: {
                    '&:before': {
                        borderBottom: '1px solid #444 !important'
                    },
                    '&:after': {
                        borderBottom: '1px solid #555 !important'
                    }
                }
            }}
            InputLabelProps={{
                style: {
                    color: '#aaa'
                }
            }}
            style={{
                backgroundColor: 'transparent',
                maxHeight: '50px'
            }}
        />
    );
};

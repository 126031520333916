import React from "react";
import { IconButton } from "@mui/material";
import styles from "../Secure.module.scss";
import { useNavigate, useLocation } from "react-router-dom";

interface FooterButtonProps {
  text: string;
  href: string;
  children: React.ReactNode;
}

export const FooterButton: React.FC<FooterButtonProps> = ({
  text,
  href,
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isButtonActive =
    location.pathname === href ||
    (href !== "/" && location.pathname.startsWith(href));

  return (
    <IconButton
      sx={{ color: "#FFFFFF" }}
      className={`${styles.footerButton} ${
        isButtonActive ? styles.footerButtonActive : ""
      }`}
      onClick={() => navigate(href)}
    >
      {children}
      <div>{text}</div>
    </IconButton>
  );
};

import React, { useEffect, useState } from 'react';
import styles from '../Secure.module.scss';
import { SettingsOutlined, SearchOutlined } from '@mui/icons-material';
import { MyStatisticsGame, ProfileTabs, StatisticPerLeague, Statisticts } from '../../../types/Generic';
import { useNavigate, useParams } from 'react-router-dom';
import { useHeader } from '../../../context/HeaderContext';
import { Methods, useFetch } from '../../../hooks/useFetch';
import { ComponentLoader } from '../../../components/ComponentLoader';
import { ProfileLeagueCard } from './components/ProfileLeagueCard';
import { useAuth, User } from '../../../context/AuthContext';

export const Profile: React.FC = () => {
    const params = useParams();

    const { user } = useAuth();
    const parsedUserId = params.id ? parseInt(params.id) : null;

    const [activeUser, setActiveUser] = useState<User | null>(null);
    const [statistics, setStatistics] = useState<Statisticts | null>(null);
    const [activeTab, setActiveTab] = useState<string>(ProfileTabs.STATISTICS);
    const { setConfig } = useHeader();
    const navigate = useNavigate();

    const { makeRequest: fetchStatistics } = useFetch(`/api/profile/statistic`, {
        method: Methods.GET
    });

    const { makeRequest: fetchUser } = useFetch(`/api/user`, {
        method: Methods.GET
    });

    const renderProfileContent = () => {
        if (!statistics) {
            return <ComponentLoader isLoading />;
        }

        if (activeTab === ProfileTabs.STATISTICS) {
            return (
                <div className={styles.profileContentMyStatistics}>
                    {statistics?.general?.map((ms: MyStatisticsGame) => {
                        return (
                            <div className={styles.profileContentMyStatisticsCard} key={ms.key}>
                                <div className={styles.profileContentMyStatisticsCardTitle}>{ms.title}</div>
                                <div className={styles.profileContentMyStatisticsCardInformation}>
                                    <div className={styles.profileContentMyStatisticsCardInformationWidget}>
                                        <div className={styles.profileContentMyStatisticsCardLabel}>No. of bets</div>
                                        <div className={styles.profileContentMyStatisticsCardValue}>
                                            {ms.number_of_bets !== null ? ms.number_of_bets : 'N/A'}
                                        </div>
                                    </div>
                                    <div className={styles.profileContentMyStatisticsCardInformationWidget}>
                                        <div className={styles.profileContentMyStatisticsCardLabel}>Avg. odd</div>
                                        <div className={styles.profileContentMyStatisticsCardValue}>
                                            {ms.avg_odd !== null ? ms.avg_odd : 'N/A'}
                                        </div>
                                    </div>
                                    <div className={styles.profileContentMyStatisticsCardInformationWidget}>
                                        <div className={styles.profileContentMyStatisticsCardLabel}>Success rate</div>
                                        <div className={styles.profileContentMyStatisticsCardValue}>
                                            {ms.success_rate !== null ? `${ms.success_rate}%` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        }

        return (
            <div className={styles.profileContentLeaguesWrapper}>
                {statistics.per_league.length ? (
                    statistics.per_league.map((pl: StatisticPerLeague, key: number) => {
                        return (
                            <ProfileLeagueCard
                                key={key}
                                logo={pl.logo}
                                name={pl.name}
                                success_rate={pl.success_rate}
                                total_bets={pl.total_bets}
                            />
                        );
                    })
                ) : (
                    <div className={styles.userListingNotFound}>No Tickets Played</div>
                )}
            </div>
        );
    };

    useEffect(() => {
        if (parsedUserId) {
            const handleFetchUser = async () => {
                const { data: response } = await fetchUser({ params: { id: parsedUserId } });

                const user = response.data;

                setActiveUser(user);
                setConfig({
                    title: user.name
                });
            };

            const handleFetchStatistics = async () => {
                const { data: response } = await fetchStatistics({ params: { id: parsedUserId } });
                setStatistics(response.data);
            };

            handleFetchUser();
            handleFetchStatistics();
        }
    }, [parsedUserId]);

    useEffect(() => {
        if (!parsedUserId) {
            setConfig({
                title: user?.name,
                icon1: <SettingsOutlined />,
                icon1Click: () => navigate('/profile/settings'),
                icon2: <SearchOutlined />,
                icon2Click: () => navigate('/profile/users')
            });

            const handleFetchStatistics = async () => {
                const { data: response } = await fetchStatistics();
                setStatistics(response.data);
            };

            handleFetchStatistics();
        }
    }, [parsedUserId]);

    return (
        <div className={styles.profileWrapper}>
            <div className={styles.profileContent}>
                <div className={styles.profileContentPicture}></div>
                <div className={styles.profileContentSection}>
                    <div className={styles.profileContentTabs}>
                        <div
                            className={`${styles.profileContentTab} ${
                                activeTab === ProfileTabs.STATISTICS ? styles.profileContentTabActive : ''
                            }`}
                        >
                            <div
                                className={styles.profileContentTabText}
                                onClick={() => setActiveTab(ProfileTabs.STATISTICS)}
                            >
                                Player's Statistics
                            </div>
                        </div>
                        <div className={styles.profileContentTabSeparator}></div>
                        <div
                            className={`${styles.profileContentTab} ${
                                activeTab === ProfileTabs.TICKETS ? styles.profileContentTabActive : ''
                            }`}
                        >
                            <div
                                className={styles.profileContentTabText}
                                onClick={() => setActiveTab(ProfileTabs.TICKETS)}
                            >
                                Player's Tickets
                            </div>
                        </div>
                    </div>
                    {renderProfileContent()}
                </div>
            </div>
        </div>
    );
};

import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useGlobalState } from '../context/GlobalStateContext';
import { BrowserRouter as Router } from 'react-router-dom';
import AppLoader from './AppLoader';

export const AppWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { error, setError, info, setInfo } = useGlobalState();

    const handleCloseSnackbar = () => {
        setError(null);
        setInfo(null);
    };

    return (
        <Router>
            <AppLoader>{children}</AppLoader>
            {(Boolean(error) || Boolean(info)) && (
                <Snackbar
                    open={true}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                >
                    <Alert
                        onClose={handleCloseSnackbar}
                        variant="filled"
                        severity={Boolean(error) ? 'error' : 'info'}
                        sx={{ width: '100%' }}
                    >
                        {error ? error : info}
                    </Alert>
                </Snackbar>
            )}
        </Router>
    );
};

import React, { useState } from "react";
import styles from "../Secure.module.scss";
import { IconButton } from "@mui/material";
import { Star, StarBorder } from "@mui/icons-material";
import { useFetch, Methods } from "../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";

interface LeagueListingCardProps {
  id: number;
  logo: string | null;
  name: string | null;
  fixtures_count: number | null;
  favorite: boolean;
  href: string;
}

export const LeagueListingCard: React.FC<LeagueListingCardProps> = ({
  id,
  logo,
  name,
  fixtures_count,
  favorite,
  href,
}) => {
  const navigate = useNavigate();

  const [isFavorite, setIsFavorite] = useState<boolean>(favorite);

  const { user } = useAuth();

  const { makeRequest: setFavorite } = useFetch(
    `/api/leagues/favorite`,
    {
      method: Methods.POST,
      body: { league_id: id, user_id: user?.id },
    },
    true
  );

  const handleFavoriteClick = async () => {
    await setFavorite();
    setIsFavorite((isFavorite) => !isFavorite);
  };

  return (
    <div
      className={`${styles.browseCountryListingWidget} ${styles.browseLeagueListingWidget}`}
      onClick={() => navigate(href)}
      style={{ zIndex: 1 }}
    >
      <img
        src={logo ? `${process.env.REACT_APP_API_URL}/api/static${logo}` : ""}
        loading="lazy"
        alt={`Logo for ${name}`}
        className={styles.browseCountryListingWidgetFlag}
      />
      <div
        className={styles.browseCountryListingWidgetInformation}
        style={{ gridTemplateRows: "auto" }}
      >
        <div className={styles.browseCountryListingWidgetCountry}>{name}</div>
        <div className={styles.browseCountryListingWidgetFixtures}>
          {fixtures_count} matches
        </div>
      </div>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          handleFavoriteClick();
        }}
        sx={{ color: "#fb8c00", zIndex: 2 }}
      >
        {isFavorite ? <Star /> : <StarBorder />}
      </IconButton>
    </div>
  );
};

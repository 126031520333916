import React, { useState, useEffect } from 'react';
import '../index.scss';
import { useLoading } from '../context/LoadingContext';
import { CircularProgress } from '@mui/material';

interface AppLoaderProps {
    children: React.ReactNode;
}

const AppLoader: React.FC<AppLoaderProps> = ({ children }) => {
    const [showLoader, setShowLoader] = useState(false);

    const { loading } = useLoading();

    useEffect(() => {
        let loaderTimeout: NodeJS.Timeout | null = null;

        if (loading) {
            loaderTimeout = setTimeout(() => {
                setShowLoader(true);
            }, 1000);
        } else {
            setShowLoader(false);
            if (loaderTimeout) {
                clearTimeout(loaderTimeout);
            }
        }

        return () => {
            if (loaderTimeout) {
                clearTimeout(loaderTimeout);
            }
        };
    }, [loading]);

    return (
        <>
            {showLoader ? (
                <div className={'appLoader'}>
                    <CircularProgress color="inherit" />
                </div>
            ) : null}
            {children}
        </>
    );
};

export default AppLoader;

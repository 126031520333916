import React from 'react';
import styles from '../Secure.module.scss';
import { LeagueListingCard } from './LeagueListingCard';
import { League } from '../../../types/DbModels';
import { useLocation } from 'react-router-dom';

interface LeagueListingProps {
    leagues: Array<League>;
}

export const LeaguesListing: React.FC<LeagueListingProps> = ({ leagues }) => {
    const location = useLocation();
    const pathname = location.pathname;

    const renderLeagues = () => {
        if (!leagues.length) {
            return <div className={styles.listingNotFound}>Leagues not found</div>;
        }

        return leagues.map((l: League) => {
            return <LeagueListingCard key={l.id} {...l} href={`${pathname}/${l.id}`} />;
        });
    };

    return (
        <div className={styles.countriesListingWrapper}>
            <div className={styles.browseCountriesListing}>{renderLeagues()}</div>
        </div>
    );
};

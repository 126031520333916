import React from 'react';
import './App.css';
import { GlobalStateProvider } from './context/GlobalStateContext.tsx';
import { AppWrapper } from './components/AppWrapper.tsx';
import { AuthenticatedApp } from './components/AuthenticatedApp.tsx';
import { LoadingProvider } from './context/LoadingContext.tsx';
import { AuthProvider } from './context/AuthContext.tsx';

const App: React.FC = () => {
    return (
        <LoadingProvider>
            <GlobalStateProvider>
                <AuthProvider>
                    <AppWrapper>
                        <AuthenticatedApp />
                    </AppWrapper>
                </AuthProvider>
            </GlobalStateProvider>
        </LoadingProvider>
    );
};

export default App;

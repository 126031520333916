import { CircularProgressProps } from '@mui/material/CircularProgress';

export const bidLabelMapping: Record<string, Record<string, string>> = {
    game1: {
        '1': '1',
        X: 'X',
        '2': '1'
    },
    game2: {
        '1': 'Under 2.5',
        '2': 'Over 2.5'
    },
    game3: {
        '11': '1-1',
        '1X': '1-X',
        '12': '1-3',
        X1: 'X-1',
        XX: 'X-X',
        X2: 'X-2',
        '21': '2-1',
        '2X': '2-X',
        '22': '2-2'
    },
    game4: {
        GG: 'GG',
        NG: 'NG'
    }
};

export const gameKeyMapping: Record<string, string> = {
    game1: 'Match Outcome',
    game2: 'Over/Under',
    game3: 'Half Time / Full Time',
    game4: 'Both Teams to Score'
};

export interface LoaderProps extends CircularProgressProps {
    message?: string;
    isLoading: boolean;
}

export enum ProfileTabs {
    STATISTICS = 'statistics',
    TICKETS = 'tickets'
}

export type Statisticts = {
    general: Array<MyStatisticsGame>;
    per_league: Array<StatisticPerLeague>;
};

export type MyStatisticsGame = {
    key: string;
    title: string;
    number_of_bets: number;
    avg_odd: number;
    success_rate: number;
};

export type StatisticPerLeague = {
    name: string;
    logo: string;
    total_bets: number;
    success_rate: number;
};

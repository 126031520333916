import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MyLeagues } from './my_leagues/MyLeagues';
import styles from './Secure.module.scss';
import { Footer } from './components/Footer';
import { Browse } from './browse/Browse';
import { Profile } from './profile/Profile';
import { Favorite } from './favorites/Favorite';
import { SearchUsers } from './profile/SearchUsers';
import { ProfileSettings } from './profile/ProfileSettings';
import { LiveFixtures } from './live_fixtures/LiveFixtures';
import { Header } from './components/Header';
import { HeaderProvider } from '../../context/HeaderContext';

const SecureNavigationWrapper: React.FC = () => {
    return (
        <HeaderProvider>
            <div className={styles.secureWrapper}>
                <Header />
                <Routes>
                    <Route path="*" element={<Navigate to="/live" replace />} />
                    <Route path="/" element={<MyLeagues />} />
                    <Route path="/browse/:id?/:league_id?/:fixture_id?" element={<Browse />} />
                    <Route path="/live/:fixture_id?" element={<LiveFixtures />} />
                    <Route path="/favorites/:league_id?/:fixture_id?" element={<Favorite />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/profile/users/:id?" element={<SearchUsers />} />
                    <Route path="/profile/settings" element={<ProfileSettings />} />
                </Routes>
                <Footer />
            </div>
        </HeaderProvider>
    );
};

export default SecureNavigationWrapper;

import React from "react";
import styles from "../Public.module.scss";
import {
  CustomTextInput as TextField,
  InputType,
} from "../components/CustomTextInput";
import { useNavigate } from "react-router-dom";
import { useValidation, validators } from "../../../hooks/useValidation";
import { useFetch, Methods } from "../../../hooks/useFetch";
import { useGlobalState } from "../../../context/GlobalStateContext";

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();

  interface User {
    email: string;
  }

  const validationConfig = {
    email: [validators.isNull, validators.isEmpty, validators.isEmailCorrect],
  };

  const { error, setInfo } = useGlobalState();

  const {
    values: user,
    errors,
    handleChange,
    validateForm,
  } = useValidation(
    {
      email: "",
    },
    validationConfig
  );

  const { makeRequest: forgotPassword, loading } = useFetch<User>(
    `/api/forgot_password`,
    {
      method: Methods.POST,
      body: user,
    }
  );

  const handleForgotPassword = async () => {
    if (validateForm()) {
      const { data: response } = await forgotPassword();

      if (response && response.data) {
        setInfo(response.data);
      }
    }
  };

  return (
    <div className={styles.wrapperContent}>
      <div className={styles.container}>
        <div className={styles.backgroundImage}>
          <div className={styles.overlay}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/logo@2x.png`}
              alt="BetBook Logo"
              className={styles.logo}
              onClick={() => navigate("/")}
            />
            <div className={styles.overlayContent}>
              <TextField
                name="email"
                placeholder="Email"
                label="Email"
                type={InputType.Text}
                value={user.email}
                error={Boolean(errors.email) || Boolean(error)}
                errorMessage={errors.email || error}
                onChange={handleChange}
              />
            </div>
            <div className={styles.overlayButtons}>
              <button
                className={styles.createAccount}
                onClick={handleForgotPassword}
                disabled={
                  loading ||
                  Object.keys(errors).some((error) => errors[error] !== null)
                }
              >
                Send me new password
              </button>
              <button
                className={styles.alreadyAccount}
                onClick={() => navigate("/login")}
              >
                I already have an account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

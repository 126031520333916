import React from 'react';
import { User } from '../../../../context/AuthContext';
import styles from '../../Secure.module.scss';
import { UserListingCard } from './UserListingCard';

interface UserListingProps {
    users: Array<User>;
}

export const UserListing: React.FC<UserListingProps> = ({ users }) => {
    if (!users.length) {
        return <div className={styles.userListingNotFound}>Users not found</div>;
    }

    return (
        <div className={styles.searchUsersListing}>
            {users.map((user: User, key: number) => {
                return (
                    <UserListingCard
                        id={user.id}
                        username={user.username}
                        created_at={user.created_at}
                        name={user.name}
                        key={key}
                    />
                );
            })}
        </div>
    );
};

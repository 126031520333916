import React, { FC } from "react";
import styles from "../../Secure.module.scss";

interface FixtureDetailsTeamCardProps {
  logo: string;
  name: string;
}

export const FixtureDetailsTeamCard: FC<FixtureDetailsTeamCardProps> = ({
  logo,
  name,
}) => {
  return (
    <div className={styles.fixtureInformationTeam}>
      <img
        src={logo ? `${process.env.REACT_APP_API_URL}/api/static${logo}` : ""}
        loading="lazy"
        alt={`Logo for ${name}`}
        className={styles.fixtureInformationTeamFlag}
      />
      <div className={styles.fixtureInformationTeamName}>{name}</div>
    </div>
  );
};

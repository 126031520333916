import React from 'react';
import styles from '../../Secure.module.scss';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

interface UserListingCardProps {
    created_at: string | null | undefined;
    name: string | null | undefined;
    username: string | null | undefined;
    id: number | null | undefined;
}

export const UserListingCard: React.FC<UserListingCardProps> = ({ created_at, name, username, id }) => {
    const navigate = useNavigate();
    return (
        <div className={styles.searchUsersWidget} onClick={() => navigate(`/profile/users/${id}`)}>
            <img
                src={'../assets/images/profile_picture.jpg'}
                loading="lazy"
                alt={`Profile pic`}
                className={styles.searchUsersPhoto}
            />
            <div className={styles.searchUsersInformation}>
                <div className={styles.searchUsersInformationSince}>
                    {created_at ? moment(created_at).format('MMMM YYYY') : 'N/A'}
                </div>
                <div className={styles.searchUsersInformationName}>{name ? name : 'N/A'}</div>
                <div className={styles.searchUsersInformationCountry}>{username ? username : 'N/A'}</div>
            </div>
        </div>
    );
};

import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Public from "./Public.tsx";
import Login from "./login/Login.tsx";
import Register from "./register/Register.tsx";
import ForgotPassword from "./forgot_password/ForgotPassword.tsx";
import RestartPassword from "./restart_password/RestartPassword.tsx";

const PublicNavigationWrapper: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/" element={<Public />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/restart-password" element={<RestartPassword />} />
      </Routes>
    </>
  );
};

export default PublicNavigationWrapper;

import React from 'react';
import styles from '../../Secure.module.scss';

interface ProfileLeagueCardProps {
    name: string;
    logo: string;
    success_rate: number;
    total_bets: number;
}

export const ProfileLeagueCard: React.FC<ProfileLeagueCardProps> = ({ name, logo, success_rate, total_bets }) => {
    return (
        <div className={styles.profileContentLeaguesWidget}>
            <img
                src={logo ? `${process.env.REACT_APP_API_URL}/api/static${logo}` : ''}
                loading="lazy"
                alt={`Logo for ${name}`}
                className={styles.profileContentLeaguesWidgetFlag}
            />
            <div className={styles.profileContentLeaguesWidgetInformation} style={{ gridTemplateRows: 'auto' }}>
                <div className={styles.profileContentLeaguesWidgetSuccessRate}>
                    <span>Success Rate</span> {success_rate}%
                </div>
                <div className={styles.profileContentLeaguesWidgetName}>{name}</div>
                <div className={styles.profileContentLeaguesWidgetBets}>{total_bets} bets</div>
            </div>
        </div>
    );
};

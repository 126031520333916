import React from 'react';
import styles from '../Secure.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Fixture } from '../../../types/DbModels';
import { bidLabelMapping, gameKeyMapping } from '../../../types/Generic';

interface FixtureListingCardProps {
    fixture: Fixture;
}

export const FixtureListingCard: React.FC<FixtureListingCardProps> = ({ fixture }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname + `/${fixture.id}`;

    type PlayedBid = {
        game: string;
        label: string | null;
        quota: number | null;
        result: 1 | 0 | null;
    };

    const extractPlayedBids = (fixture: Fixture): PlayedBid[] => {
        const playedBids: PlayedBid[] = [];

        for (const gameKey in gameKeyMapping) {
            let bidFound = false;

            for (const key in fixture) {
                if (key.startsWith(gameKey) && key.endsWith('_bid') && fixture[key as keyof Fixture] !== 0) {
                    const label = key.split('_')[1];
                    const quotaKey = `${gameKey}_${label}_quota`;
                    const resultKey = `${gameKey}_${label}_result`;

                    const resultValue = fixture[resultKey as keyof Fixture];
                    let result: 1 | 0 | null = null;

                    if (resultValue === 1) {
                        result = 1;
                    } else if (resultValue === 0) {
                        result = 0;
                    }

                    playedBids.push({
                        game: gameKeyMapping[gameKey],
                        label: bidLabelMapping[gameKey]?.[label.toUpperCase()] || label,
                        quota: fixture[quotaKey as keyof Fixture] as number,
                        result
                    });

                    bidFound = true;
                    break;
                }
            }

            if (!bidFound) {
                playedBids.push({
                    game: gameKeyMapping[gameKey],
                    label: null,
                    quota: null,
                    result: null
                });
            }
        }

        return playedBids;
    };

    const playedBids = extractPlayedBids(fixture);

    const renderFixtureCard = () => {
        if (fixture.not_bidded && fixture.biddable) {
            //# Not bidded
            return (
                <div className={styles.fixturesBidWrapper} onClick={() => navigate(pathname)}>
                    <div className={styles.fixturesBidWrapperInfo}>
                        <div
                            className={`${styles.fixturesBidWrapperInfoTeams}`}
                            style={{ justifyContent: 'flex-start' }}
                        >
                            <img
                                src={
                                    fixture.team_home_logo
                                        ? `${process.env.REACT_APP_API_URL}/api/static${fixture.team_home_logo}`
                                        : ''
                                }
                                loading="lazy"
                                alt={`Logo for ${fixture.team_home_name}`}
                                className={styles.browseCountryListingWidgetFlag}
                            />
                            <div className={styles.fixturesBidWrapperInfoTeamsName} style={{ textAlign: 'left' }}>
                                {fixture.team_home_name}
                            </div>
                        </div>
                        <div className={styles.fixturesBidWrapperInfoTime}>
                            {fixture.date ? moment(fixture.date).format('ddd HH:mm DD.MM.YYYY') : 'N/A'}
                        </div>
                        <div className={`${styles.fixturesBidWrapperInfoTeams}`} style={{ justifyContent: 'flex-end' }}>
                            <div className={styles.fixturesBidWrapperInfoTeamsName} style={{ textAlign: 'right' }}>
                                {fixture.team_away_name}
                            </div>
                            <img
                                src={
                                    fixture.team_away_logo
                                        ? `${process.env.REACT_APP_API_URL}/api/static${fixture.team_away_logo}`
                                        : ''
                                }
                                loading="lazy"
                                alt={`Logo for ${fixture.team_away_name}`}
                                className={styles.browseCountryListingWidgetFlag}
                            />
                        </div>
                    </div>
                    <div className={styles.fixturesBidWrapperMakeABid}>MAKE A BID</div>
                </div>
            );
        }

        if (fixture.biddable && !fixture.not_bidded) {
            //# Bidded
            return (
                <div className={styles.fixturesFinishedWrapper} onClick={() => navigate(pathname)}>
                    <div className={styles.fixturesFinishedWrapperInfo}>
                        <div
                            className={`${styles.fixturesFinishedWrapperInfoTeams}`}
                            style={{ justifyContent: 'flex-start' }}
                        >
                            <img
                                src={
                                    fixture.team_home_logo
                                        ? `${process.env.REACT_APP_API_URL}/api/static${fixture.team_home_logo}`
                                        : ''
                                }
                                loading="lazy"
                                alt={`Logo for ${fixture.team_home_name}`}
                                className={styles.browseCountryListingWidgetFlag}
                            />
                            <div className={styles.fixturesFinishedWrapperInfoTeamsName} style={{ textAlign: 'left' }}>
                                {fixture.team_home_name}
                            </div>
                        </div>
                        <div className={styles.fixturesBidWrapperInfoTime}>
                            {fixture.date ? moment(fixture.date).format('ddd HH:mm DD.MM.YYYY') : 'N/A'}
                        </div>
                        <div
                            className={`${styles.fixturesFinishedWrapperInfoTeams}`}
                            style={{ justifyContent: 'flex-end' }}
                        >
                            <div className={styles.fixturesFinishedWrapperInfoTeamsName} style={{ textAlign: 'right' }}>
                                {fixture.team_away_name}
                            </div>
                            <img
                                src={
                                    fixture.team_away_logo
                                        ? `${process.env.REACT_APP_API_URL}/api/static${fixture.team_away_logo}`
                                        : ''
                                }
                                loading="lazy"
                                alt={`Logo for ${fixture.team_away_name}`}
                                className={styles.browseCountryListingWidgetFlag}
                            />
                        </div>
                    </div>
                    <div className={styles.fixturesFinishedWrapperBids}>
                        {playedBids.map((playerBid: PlayedBid, index: number) => {
                            const className = `${styles.fixturesFinishedWrapperBid} ${styles.fixturesFinishedWrapperSelected}`;

                            return playerBid.label && playerBid.quota ? (
                                <div className={className} key={index}>
                                    <div className={styles.fixturesFinishedWrapperBidLabel}>{playerBid.label}</div>
                                    <div className={styles.fixturesFinishedWrapperBidQuote}>{playerBid.quota}</div>
                                </div>
                            ) : (
                                <div key={index}></div>
                            );
                        })}
                    </div>
                </div>
            );
        }

        if (!fixture.biddable && fixture.live) {
            //# Live
            return (
                <div
                    className={`${styles.fixturesFinishedWrapper} ${styles.fixturesLiveWrapper}`}
                    onClick={() => navigate(pathname)}
                >
                    <div className={styles.fixturesLiveSymbolWrapper}>
                        <div className={styles.fixturesLiveSymbol}>
                            {fixture.short_status} - {fixture.elapsed}' min.
                        </div>
                    </div>
                    <div className={styles.fixturesFinishedWrapperInfo}>
                        <div
                            className={`${styles.fixturesFinishedWrapperInfoTeams}`}
                            style={{ justifyContent: 'flex-start' }}
                        >
                            <img
                                src={
                                    fixture.team_home_logo
                                        ? `${process.env.REACT_APP_API_URL}/api/static${fixture.team_home_logo}`
                                        : ''
                                }
                                loading="lazy"
                                alt={`Logo for ${fixture.team_home_name}`}
                                className={styles.browseCountryListingWidgetFlag}
                            />
                            <div className={styles.fixturesFinishedWrapperInfoTeamsName} style={{ textAlign: 'left' }}>
                                {fixture.team_home_name}
                            </div>
                        </div>
                        <div
                            className={styles.fixturesFinishedWrapperInfoScore}
                            style={{ color: 'rgba(255, 172, 58, 1)' }}
                        >
                            {fixture.team_home_goals} : {fixture.team_away_goals}
                        </div>
                        <div
                            className={`${styles.fixturesFinishedWrapperInfoTeams}`}
                            style={{ justifyContent: 'flex-end' }}
                        >
                            <div className={styles.fixturesFinishedWrapperInfoTeamsName} style={{ textAlign: 'right' }}>
                                {fixture.team_away_name}
                            </div>
                            <img
                                src={
                                    fixture.team_away_logo
                                        ? `${process.env.REACT_APP_API_URL}/api/static${fixture.team_away_logo}`
                                        : ''
                                }
                                loading="lazy"
                                alt={`Logo for ${fixture.team_away_name}`}
                                className={styles.browseCountryListingWidgetFlag}
                            />
                        </div>
                    </div>
                    <div className={styles.fixturesFinishedWrapperBids}>
                        {playedBids.map((playerBid: PlayedBid, index: number) => {
                            const className = `${styles.fixturesFinishedWrapperBid} ${styles.fixturesFinishedWrapperSelected}`;

                            return playerBid.label && playerBid.quota ? (
                                <div className={className} key={index}>
                                    <div className={styles.fixturesFinishedWrapperBidLabel}>{playerBid.label}</div>
                                    <div className={styles.fixturesFinishedWrapperBidQuote}>{playerBid.quota}</div>
                                </div>
                            ) : (
                                <div key={index}></div>
                            );
                        })}
                    </div>
                </div>
            );
        }

        if (!fixture.biddable && !fixture.live) {
            //# Finsihed
            return (
                <div className={styles.fixturesFinishedWrapper} onClick={() => navigate(pathname)}>
                    <div className={styles.fixturesFinishedWrapperInfo}>
                        <div
                            className={`${styles.fixturesFinishedWrapperInfoTeams}`}
                            style={{ justifyContent: 'flex-start' }}
                        >
                            <img
                                src={
                                    fixture.team_home_logo
                                        ? `${process.env.REACT_APP_API_URL}/api/static${fixture.team_home_logo}`
                                        : ''
                                }
                                loading="lazy"
                                alt={`Logo for ${fixture.team_home_name}`}
                                className={styles.browseCountryListingWidgetFlag}
                            />
                            <div className={styles.fixturesFinishedWrapperInfoTeamsName} style={{ textAlign: 'left' }}>
                                {fixture.team_home_name}
                            </div>
                        </div>
                        <div className={styles.fixturesFinishedWrapperInfoScore}>
                            {fixture.team_home_goals} : {fixture.team_away_goals}
                        </div>
                        <div
                            className={`${styles.fixturesFinishedWrapperInfoTeams}`}
                            style={{ justifyContent: 'flex-end' }}
                        >
                            <div className={styles.fixturesFinishedWrapperInfoTeamsName} style={{ textAlign: 'right' }}>
                                {fixture.team_away_name}
                            </div>
                            <img
                                src={
                                    fixture.team_away_logo
                                        ? `${process.env.REACT_APP_API_URL}/api/static${fixture.team_away_logo}`
                                        : ''
                                }
                                loading="lazy"
                                alt={`Logo for ${fixture.team_away_name}`}
                                className={styles.browseCountryListingWidgetFlag}
                            />
                        </div>
                    </div>
                    <div className={styles.fixturesFinishedWrapperBids}>
                        {playedBids.map((playerBid: PlayedBid, index: number) => {
                            const className = `${styles.fixturesFinishedWrapperBid} ${
                                playerBid.result === 1
                                    ? styles.fixturesFinishedWrapperWon
                                    : styles.fixturesFinishedWrapperLost
                            }`;

                            return playerBid.label && playerBid.quota ? (
                                <div className={className} key={index}>
                                    <div className={styles.fixturesFinishedWrapperBidLabel}>{playerBid.label}</div>
                                    <div className={styles.fixturesFinishedWrapperBidQuote}>{playerBid.quota}</div>
                                </div>
                            ) : (
                                <div key={index}></div>
                            );
                        })}
                    </div>
                </div>
            );
        }
    };

    return renderFixtureCard();
};
